import { FC } from 'react';
import IconProps from './IconProps';

const DownloadIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14.1075C6 13.5552 5.55228 13.1075 5 13.1075C4.44772 13.1075 4 13.5552 4 14.1075H6ZM20.81 14.1075C20.81 13.5552 20.3623 13.1075 19.81 13.1075C19.2577 13.1075 18.81 13.5552 18.81 14.1075H20.81ZM16.8149 11.1121C17.2054 10.7216 17.2054 10.0884 16.8149 9.69789C16.4243 9.30737 15.7912 9.30737 15.4006 9.69789L16.8149 11.1121ZM11.6981 13.4004C11.3076 13.7909 11.3076 14.4241 11.6981 14.8146C12.0887 15.2051 12.7218 15.2051 13.1124 14.8146L11.6981 13.4004ZM11.6994 14.8146C12.0899 15.2051 12.7231 15.2051 13.1136 14.8146C13.5041 14.4241 13.5041 13.7909 13.1136 13.4004L11.6994 14.8146ZM9.41111 9.69789C9.02058 9.30737 8.38742 9.30737 7.99689 9.69789C7.60637 10.0884 7.60637 10.7216 7.99689 11.1121L9.41111 9.69789ZM11.4043 14.1075C11.4043 14.6598 11.852 15.1075 12.4043 15.1075C12.9566 15.1075 13.4043 14.6598 13.4043 14.1075H11.4043ZM13.4043 3C13.4043 2.44772 12.9566 2 12.4043 2C11.852 2 11.4043 2.44772 11.4043 3H13.4043ZM4 14.1075V15.0331H6V14.1075H4ZM4 15.0331C4 17.1191 5.69098 18.81 7.77688 18.81V16.81C6.79553 16.81 6 16.0145 6 15.0331H4ZM7.77688 18.81H17.0331V16.81H7.77688V18.81ZM17.0331 18.81C19.1191 18.81 20.81 17.1191 20.81 15.0331H18.81C18.81 16.0145 18.0145 16.81 17.0331 16.81V18.81ZM20.81 15.0331V14.1075H18.81V15.0331H20.81ZM15.4006 9.69789L11.6981 13.4004L13.1124 14.8146L16.8149 11.1121L15.4006 9.69789ZM13.1136 13.4004L9.41111 9.69789L7.99689 11.1121L11.6994 14.8146L13.1136 13.4004ZM13.4043 14.1075V3H11.4043V14.1075H13.4043Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownloadIcon;
